.text-updater-node {
  /*height: 50px;*/
  border: 1px solid #eee;
  border-radius: 5px;
  background: white;
}

.text-updater-node-inner {
  /*padding: 5px;*/
}

.bottom-button {
  background-color: white;
  width: 100%;
  height: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-top: 5px;
  /*border: greenyellow 1px solid;*/
  background-color: #29dd29;
  color: white;
}

.bottom-button span {
  bottom: 0.2rem;
  position: absolute;
  width: 100%;
  text-align: center;
  left: 0;
}

/*.text-updater-node label {
  display: block;
  color: #777;
  font-size: 12px;
}*/

.message-label {
  white-space:pre-wrap;
}

.react-flow__handle.react-flow__handle-right.nodrag.nopan.source.connectable {
    border: #0059dc 1px solid;
    background-color: white;
}

.react-flow__handle.react-flow__handle-left.nodrag.nopan.target.connectable {
  border: #dc8000 1px solid;
  background-color: white;
}

.delete-option-btn {
  left: 0.5em;
  top: 50%;
  transform: translate(0, -50%);
  position: absolute;
  color: red;
  display: none;
}

.delete-option-btn-shown {
  display: inline-block;
}

.option-container {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  text-align: left;
}

.option-container.option-container-del-btn-shown {
  padding-left: 25px;
}

.delete-node-btn {
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  color: red;
}

.floating-add {
  top: 0;
  right: 0;
}

.react-flow__handle-left {
  left: -6px;
}

.react-flow__handle-right {
  right: -7px;
}

.react-flow__handle {
  height: 12px;
  width: 12px;
}